<template>
	<main>
		<div class="el-content">
			<table-search v-model="search_data" :column="column" @clean="clean" @search="search"/>
			<div class="action-box">
				<div class="action">
					<el-button class="el-icon-refresh" @click="all"></el-button>
					<el-button class="el-icon-plus" v-if="show_create" @click="create">创建</el-button>
					<el-popconfirm title="确定要删除选中的数据吗？" @confirm="deleteChoose">
						<template #reference>
							<el-button icon="el-icon-delete">删除</el-button>
						</template>
					</el-popconfirm>
					<template v-if="import_visible">
						<el-button class="el-icon-download" @click="templateFile">下载导入模板</el-button>
						<el-button class="upload-btn el-icon-upload" :loading="upload_loading">批量导入
							<input type="file" id="file" @change="uploadFile" accept=".xlsx">
						</el-button>
					</template>
				</div>
				<div class="other">
					<table-column :column="column" @change="changeColumn"/>
					<el-button v-if="export_visible" class="el-icon-download" @click="exportAll" :loading="export_loading" title="导出"></el-button>
				</div>
			</div>
			<div class="table-box">
				<table-table :table_loading="table_loading" :table_data="table_data" :column_visible="column_visible" :column="column" @selection="selection" @sortChange="sortChange" @changeSort="changeSort">
					<template #column="{scope,item}"></template>
					<template #action="{scope}">
						<div class="el-link-group">
							<el-link type="primary" @click="editor(scope.row)">回复</el-link>
							<el-popconfirm title="确定要删除该数据吗？" @confirm="deleted(scope.row)">
								<template #reference>
									<el-link type="primary">删除</el-link>
								</template>
							</el-popconfirm>
						</div>
					</template>
				</table-table>
			</div>
			<div class="page-box">
				<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="page_info.page" :page-sizes="[10, 15, 20, 50,100]" :page-size="page_info.limit" layout="total,sizes,prev,pager,next,jumper" :total="page_info.total" hide-on-single-page background></el-pagination>
			</div>
		</div>
		<table-import v-if="import_visible" ref="importRef" :import_field="import_field" @saveAll="saveAll"/>
		<components-create v-if="create_visible" ref="createRef" :title="title" :column_option="column_option" @success="all" @close="closeCreate"/>
	</main>
</template>
<script>
import ComponentsCreate from "./Create.vue"
import {MixinIndex} from "@/service/admin/mixinIndex";
import {feedback as api, user} from "@/api/admin";

export default {
	components: {ComponentsCreate},
	mixins: [MixinIndex],
	data() {
		return {
			api,
			title: "反馈",
			column_option: {
				status: [{id: 1, title: "已回复"}, {id: 2, title: "未回复"}],
			},
			show_create:false,
		}
	},
	computed: {
		column() {
			return [
				{label: "ID", prop: "id",  sortable: true},
				{label: "姓名", prop: "user.username", search: true,type:'api',option:{api:user.all,field:'user_id',title:'username'}},
				{label: "工号", prop: "user.code"},
				{label: "留言", prop: "message", type: "textarea"},
				{label: "回复", prop: "replay",type: "textarea"},
				{label: "状态", prop: "status", type: "option", search: true, option: this.column_option.status},
				{label: "反馈时间", prop: "create_time", type: 'datetime', sortable: true},
				{label: "回复时间", prop: "replay_time", type: 'datetime', sortable: true},
				{label: "操作", prop: "action", fixed: 'right', type: 'action'},
			]
		}
	}
}
</script>
