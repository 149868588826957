<template>
	<main>
		<el-dialog :visible="true" @close="close">
			<template slot="title">
				<div class="title">{{query.id ? '编辑' : '创建'}}{{ title }}</div>
			</template>
			<div class="form-box">
				<el-form class="create-form" ref="formRef" :rules="rules" :model="form" label-width="100px">
					<el-form-item label="反馈人" prop="user_id" >
						<form-api v-model="form.user_id" :api="user.all" title="username" disabled/>
					</el-form-item>
					<el-form-item label="反馈内容" prop="message">
						<el-input type="textarea" v-model="form.message" disabled/>
					</el-form-item>
					<el-form-item label="回复内容" prop="replay">
						<el-input type="textarea" v-model="form.replay"/>
					</el-form-item>
					<div class="form-flex">
						<el-form-item label="反馈时间" prop="create_time">
							<form-date-picker v-model="form.create_time" type="datetime" disabled/>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" :loadin="submit_loading" @click="submit">保 存</el-button>
			</div>
		</el-dialog>
	</main>
</template>

<script>
import {feedback as api, user} from "@/api/admin";
import {MixinCreate} from "@/service/admin/mixinCreate";
export default {
	mixins:[MixinCreate],
	data(){
		return {
			api,
			user,
		}
	},
	computed:{
		rules(){
			return {
				replay:[
					{required:true,message:"请输入回复"}
				]
			}
		}
	},
}
</script>
